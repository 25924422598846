import '../assets/js/main.js';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Link } from "react-scroll";

const Navbar = () => {
    const location = useLocation();

    const [theme, setTheme] = useState(
        localStorage.getItem('theme') || 'light'
    );

    const toggleTheme = () => {
        if (theme === 'light') {
            setTheme('dark');
            $(".toggle-theme i").addClass("fa-sun");
            $(".toggle-theme i").removeClass("fa-moon");
        } else {
            setTheme('light');
            $(".toggle-theme i").addClass("fa-moon");
            $(".toggle-theme i").removeClass("fa-sun");
        }
    };
    useEffect(() => {
        localStorage.setItem('theme', theme);
        document.body.className = theme;
    }, [theme]);

    window.addEventListener('load', (event) => {
        $(".preloader").fadeOut("slow");
    });



    return (
        <>
            <header>
                <Link className="logo" activeClass="active" smooth spy to="home" offset={-400}><jk>pk</jk></Link>
                <nav>
                    <ul className="nav__links">
                        <Link activeClass="active" smooth spy to="home" offset={-400}><li><i class="fa-solid fa-home"></i> <span className="barname">Home</span></li></Link>
                        <Link activeClass="active" smooth spy to="about" offset={-120}><li><i class="fa-solid fa-user"></i> <span className="barname">About</span></li></Link>
                        <Link activeClass="active" smooth spy to="projects" offset={-130}><li><i class="fa-solid fa-folder-open"></i> <span className="barname">Projects</span></li></Link>
                        <Link activeClass="active" smooth spy to="contact" offset={-80}><li><i class="fa-solid fa-phone"></i> <span className="barname">Contact</span></li></Link>
                        <a><li className="toggle-theme" onClick={toggleTheme}><i className="fa-solid fa-sun"></i></li></a>
                    </ul>
                </nav>
            </header>

        </>
    );
};

export default Navbar;