import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./home.js";

import Navbar from './subpages/navbar.js';
import Preloader from './assets/js/preloader.js';

import AnimatePage from './subpages/animatepage.js';
import { AnimatePresence } from "framer-motion";


import { useLocation } from 'react-router-dom';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0
    });
  }, [pathname]);

  return null;
};

const PageNotFound = () => (
  <div>
    <section className="about" style={{ minHeight: "100vh" }}>
      <section className="home" >
        <section className="privacy" style={{ marginTop: "12%" }}>
          <span className="privacy_policy"><span style={{ color: "#768D70", fontFamily: "Cocon", fontSize: "80px" }}>404</span><span style={{ display: "block", fontSize: "22px" }}>Nie znaleziono strony</span></span>
        </section>
      </section>
    </section>
  </div >
)

function Spinner() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div style={{ display: loading ? "" : "none", overflowX: "hidden", overflowY: "hidden" }} className="preloader">
      <span></span>
    </div>
  );
}

function App() {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);
  return (
    <Router>
      <Spinner />
      <ScrollToTop />
      <Navbar />
      <AnimatePresence exitBeforeEnter>
        <Routes>
          <Route exact path='/' element={<Home />} />

        </Routes>
      </AnimatePresence>

    </Router>
  );
}

export default App;