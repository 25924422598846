import './assets/css/styles.css';
import './assets/js/main.js';
import $ from 'jquery';
import React, { useEffect, useState, useRef } from 'react';
import Preloader from "./assets/js/preloader.js";
import { NavLink, useHistory } from "react-router-dom";


import { useCookies } from 'react-cookie';

import OwlCarousel from 'react-owl-carousel';
import './assets/css/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import AnimatePage from './subpages/animatepage.js';

import { useForm, ValidationError } from '@formspree/react';

import { Link } from "react-scroll";


const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 1000,
    nav: false,
    dots: false,
    mouseDrag: false,
    touchDrag: false,
    margin: 10,
    center: true,
    responsive: {
        0: {
            items: 1
        },
        780: {
            items: 1
        },
        1200: {
            items: 1
        },
        1800: {
            items: 1
        }
    }
};


const responsive = {
    0: {
        dotsEach: 6,
        items: 1,
    },
    600: {
        dotsEach: 6,
        items: 2,
    },
    1300: {
        dotsEach: 6,
        items: 3,
    },
    1600: {
        dotsEach: 6,
        items: 4,
    }
};



function ContactForm() {
    const [state, handleSubmit, reset] = useForm("moqzblnv");

    if (state.succeeded) {
        for (const form of document.getElementsByTagName('form')) {
            form.reset();
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    return (<section className="contact animation">
        <div className="titleabout" id='contact'>Get in touch</div>
        <div class="contact-form">
            <form onSubmit={handleSubmit}>
                <div class="row">
                    <div class="col-lg-6" style={{ paddingRight: "50px" }}>
                        <div class="form-group">
                            <input type="text" name="name" placeholder="Full Name" class="form-control" />
                        </div>
                    </div>
                    <div class="col-lg-6" style={{ paddingRight: "50px", width: "400px" }}>
                        <div class="form-group">
                            <input type="text" name="email" placeholder="Email Adress" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="text" name="subject" placeholder="Subject" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <textarea name="message" placeholder="Your Message" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <button type="submit" disabled={state.submitting} class="btn btn-2"><i class="fa-solid fa-envelope"></i> Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </section>
    );
}

const Home = () => {
    const observer = useRef(null);

    useEffect(() => {
        let delay = 800; // Initial delay value
        observer.current = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setTimeout(() => {
                            entry.target.classList.add('animation-show');
                        }, entry.target.classList.contains('timing') ? delay : 0); // Apply delay if element has 'timing' class
                        delay += entry.target.classList.contains('timing') ? 60 : 0; // Increment delay for elements with 'timing' class
                    }
                });
            },
            {
                rootMargin: '-100px 0px 0px 0px', // Adjust the offset as needed
            }
        );

        const animationElements = document.querySelectorAll('.animation');
        animationElements.forEach((el) => observer.current.observe(el));

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, []);

    return (
        <div>
            <AnimatePage>
                <section className="home">
                    <div className="content">
                        <section className="startname" id='home'>
                            <div className="welcome animation timing">Hello, my name is</div>
                            <span className="namesubname animation timing">Patryk<jk style={{ marginLeft: "-15px" }}> Kuna</jk></span>
                            <div className="hello animation timing">I am a <lp className="bolden">front-end developer</lp> and <lp className="bolden">UX/UI designer</lp> who loves creating user-friendly websites using <lp className="bolden">React.js</lp>. My expertise in these areas allows me to design and develop ones that are both eye-catching and highly functional.</div>
                            <div className="resume-cont animation timing"><Link activeClass="active" smooth spy to="contact" offset={-100}><div className="resume-button">Get in touch</div></Link><a href="../resume.pdf" target="_blank"><div className="resume">Resume</div></a></div>
                            <div className="quote animation timing">
                                <i class="fa-regular fa-quote-left"></i>
                                The best design is the simplest one that works.
                                <i class="fa-regular fa-quote-right"></i>
                                <a href="https://en.wikipedia.org/wiki/Albert_Einstein" target="_blank" className="clickable"><span className="einstein">Albert Einstein</span></a>
                            </div>
                            <div class="social-icons animation timing">
                                <a href="https://github.com/Patis0nek" target="_blank" className="clickable"><i class="fab fa-github"></i></a>
                                <a href="https://www.linkedin.com/in/patrykkuna" target="_blank" className="clickable"><i class="fab fa-linkedin-in"></i></a>
                                <a href="https://x.com/Patis0nek" target="_blank" className="clickable"><i class="fab fa-x-twitter"></i></a>
                                <a href="https://codepen.io/Patis0nek" target="_blank" className="clickable"><i class="fab fa-codepen"></i></a>
                                <span class="line"></span>
                            </div>
                            <div class="social-icons r-icons animation timing">
                                <div className="email"><a href="mailto:kuna.patryk@gmail.com">kuna.patryk@gmail.com</a></div>
                                <span class="line"></span>
                            </div>
                        </section>
                        <div className="titleabout animation">About Me</div>
                        <section className="aboutme" id='about' style={{ paddingBottom: "200px" }}>
                            <div className="textabout animation">I'm Patryk, and I enjoy working on creative projects. My interest in design began in 2019 when a friend suggested I contribute to our school's newspaper. I learned a lot about graphic design during that time.<br /><br />In 2020, while in high school, I discovered my passion for programming. Although our IT lessons primarily focused on C++, I used my free time to work on various projects at home, including building websites.<br /><br />I am currently studying Computer Physics at <a href="https://www.agh.edu.pl/en" target="_blank" className="clickable">AGH University of Science and Technology</a> in Kraków. <br /><br />
                                Over the past five years of learning, I have managed to familiarize myself with the following technologies<br /><br />
                            </div>
                            <ul style={{ fontWeight: "200" }} className="langicons animation">
                                <li><i class="fab fa-html5"></i> HTML5</li>
                                <li><i class="fab fa-css3-alt"></i> CSS</li>
                                <li className="react-logo"><i class="fa-brands fa-react" style={{ fontWeight: "600" }}></i> React.js</li>
                                <li><i class="fab fa-js"></i> JavaScript</li>
                                <li><i class="fab fa-cuttlefish"></i> C++</li>
                                <li><i class="fab fa-python"></i> Python</li>
                            </ul>
                            <div className="textabout ndtext animation">Combining my skills and passions is vital to achieving my goals. Here are a few things I love doing<br /><br /></div>
                            <OwlCarousel className="owl-theme animation" {...options} style={{ maxWidth: '1080px', marginTop: '0px' }}>
                                <div className="item"><i className="fa-solid fa-planet-ringed"></i> Learning about Aerospace engineering</div>
                                <div className="item"><i className="fa-solid fa-person-snowboarding"></i> Snowboarding</div>
                                <div className="item"><i className="fa-solid fa-chess"></i> Practicing chess tactics</div>
                                <div className="item"><i className="fa-solid fa-futbol"></i> Playing football</div>
                                <div className="item"><i className="fa-solid fa-podcast"></i> Listening to science podcasts</div>
                                <div className="item"><i className="fa-solid fa-cube"></i> Solving Rubik's cubes</div>
                            </OwlCarousel>
                        </section>
                        <div className="titleabout animation">Projects</div>
                        <section className="projects" id='projects'>
                            <div className="project animation">
                                <div className="description">
                                    <div className="names">Sneaker release app</div>
                                    <span>This is a web application that provides information on upcoming fashion trends. Users can track the latest and past releases, receive updates on current drafts and sales, and read news from the sneaker community.</span>
                                    <div className="linksite">React | Google Firebase | jQuery <a href="https://drops0n-react.netlify.app" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
                                </div>
                                <img src="../dropsoon.png" alt="dropsoon" />
                            </div>
                            <div className="project animation">
                                <div className="description">
                                    <div className="names">Sports event website</div>
                                    <span>It is an informational website for a real school event. I designed the graphics and provided several data on sports disciplines, along with minimalistic icons. Moreover, the website's simple and uncluttered design allows for a clean and user-friendly experience for visitors.</span>
                                    <div className="linksite">React | Bootstrap | OwlCarousel <a href="https://github.com/Patis0nek/Dzien-Sportu" target="_blank"><i class="fab fa-github"></i></a><a href="https://dziensportu.netlify.app/" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
                                </div>
                                <img src="../dziensportu.png" alt="dziensportu" />
                            </div>
                            <div className="project animation">
                                <div className="description">
                                    <div className="names">Payment System Presentation</div>
                                    <span>This is one of the first projects that I created. I developed this website as a presentation for my entrepreneurship classes, utilizing a wide range of JavaScript effects in its creation.</span>
                                    <div className="linksite">JavaScript | Popper | Plotly <a href="https://github.com/Patis0nek/Payment-System-Presentation" target="_blank"><i class="fab fa-github"></i></a><a href="https://prezentacja-blik.netlify.app/" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
                                </div>
                                <img src="../blik.png" alt="blik" />
                            </div>
                            <div className="project animation">
                                <div className="description">
                                    <div className="names">Lottery machine</div>
                                    <span>I created this project to help my teacher, who was struggling with the random selection of students for short tests using a random number generator. This method significantly expedited the subsequent selection process.</span>
                                    <div className="linksite">React | Google Firebase <a href="https://github.com/Patis0nek/Fizos" target="_blank"><i class="fab fa-github"></i></a><a href="https://fizyka-losowanie.netlify.app" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
                                </div>
                                <img src="../fiz.png" alt="fizos" />
                            </div>
                        </section>
                        <section className="projects-more">
                            <div className="proj animation">
                                <div className="description">
                                    <div className="title"><i class="fa-solid fa-messages"></i>Global chat</div>
                                    Worm Chat is a messenger-like chat application that allows you to communicate on global chat channel with other registered users.
                                    <div className="linksite">Google Firebase | Google Auth <a href="https://github.com/Patis0nek/Worm-Chat" target="_blank"><i class="fab fa-github linko linko2"></i></a><a href="https://worm-chat.netlify.app/" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square linko"></i></a></div>
                                </div>
                            </div>
                            <div className="proj animation">
                                <div className="description">
                                    <div className="title"><i class="fa-solid fa-solar-system"></i>Solar System animation</div>
                                    The Solar System animation project is a straightforward simulation depicting the Sun and its planets orbiting it within their designated paths, accurately representing their relative velocities.
                                    <div className="linksite">CSS<a href="https://codepen.io/Patis0nek/full/yLREpao" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square linko"></i></a></div>
                                </div>
                            </div>
                            <div className="proj animation">
                                <div className="description">
                                    <div className="title"><i class="fa-solid fa-calculator"></i>Tip calculator</div>
                                    This project is a basic calculator designed for groups of friends who want to determine how much each person should pay, including the tip.
                                    <div className="linksite">JavaScript<a href="https://tipp-calculator.netlify.app/" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square linko"></i></a></div>
                                </div>
                            </div>
                        </section>
                        {/*
                        <section class="testimonials section-padding" id="testimonials" data-scroll-index="4">
                            <OwlCarousel className="testimonials-container">
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-8">
                                            <div class="section-title">
                                                <h2>What our<span> client</span> say</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="testimonials-carousel">
                                            <div class="testimonial-item">
                                                <div class="img-box">
                                                    <img src="/testimonial/1.jpg" alt="testimonial" />
                                                    <i class="fas fa-quote-right"></i>
                                                </div>
                                                <p>Przykładowy tekst w tym</p>
                                                <h3>Przykładowy tekst</h3>
                                                <span>UI designer</span>
                                                <div class="rating">
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                </div>
                                            </div>
                                            <div class="testimonial-item">
                                                <div class="img-box">
                                                    <img src="/testimonial/2.jpg" alt="testimonial" />
                                                    <i class="fas fa-quote-right"></i>
                                                </div>
                                                <p>Przykładowy tekst w tym</p>
                                                <h3>Przykładowy tekst</h3>
                                                <span>UI designer</span>
                                                <div class="rating">
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                </div>
                                            </div>
                                            <div class="testimonial-item">
                                                <div class="img-box">
                                                    <img src="/testimonial/3.jpg" alt="testimonial" />
                                                    <i class="fas fa-quote-right"></i>
                                                </div>
                                                <p>Przykładowy tekst w tym</p>
                                                <h3>Przykładowy tekst</h3>
                                                <span>UI designer</span>
                                                <div class="rating">
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                </div>
                                            </div>
                                            <div class="testimonial-item">
                                                <div class="img-box">
                                                    <img src="/testimonial/4.jpg" alt="testimonial" />
                                                    <i class="fas fa-quote-right"></i>
                                                </div>
                                                <p>Przykładowy tekst w tym</p>
                                                <h3>Przykładowy tekst</h3>
                                                <span>UI designer</span>
                                                <div class="rating">
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star-half"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </section>

                        <section class="pricing section-padding" id="pricing" data-scroll-index="5">
                            <div class="container">
                                <div className="titleabout animation">Pricing Plan</div>
                                <div class="row justify-content-center">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="pricing-plan">
                                            <div class="pricing-header">
                                                <h3>basic</h3>
                                            </div>
                                            <div class="pricing-price">
                                                <span class="currency">$</span>
                                                <span class="price">329.99</span>
                                                <span class="period"></span>
                                            </div>
                                            <div class="pricing-body">
                                                <ul>
                                                    <li><i class="fas fa-check"></i> 5 GB Bandwidth</li>
                                                    <li><i class="fas fa-check"></i> Free Update</li>
                                                    <li><i class="fas fa-check"></i> High Resolution Printing</li>
                                                    <li><i class="fas fa-check"></i> Branding</li>
                                                    <li><i class="fas fa-check"></i> Another great features</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="pricing-plan">
                                            <div class="pricing-header">
                                                <h3>premium</h3>
                                            </div>
                                            <div class="pricing-price">
                                                <span class="currency">$</span>
                                                <span class="price">781.99</span>
                                                <span class="period"></span>
                                            </div>
                                            <div class="pricing-body">
                                                <ul>
                                                    <li><i class="fas fa-check"></i> 5 GB Bandwidth</li>
                                                    <li><i class="fas fa-check"></i> Free Update</li>
                                                    <li><i class="fas fa-check"></i> High Resolution Printing</li>
                                                    <li><i class="fas fa-check"></i> Branding</li>
                                                    <li><i class="fas fa-check"></i> Another great features</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="pricing-plan">
                                            <div class="pricing-header">
                                                <h3>ultimate</h3>
                                            </div>
                                            <div class="pricing-price">
                                                <span class="currency">$</span>
                                                <span class="price">1458.99</span>
                                                <span class="period"></span>
                                            </div>
                                            <div class="pricing-body">
                                                <ul>
                                                    <li><i class="fas fa-check"></i> 5 GB Bandwidth</li>
                                                    <li><i class="fas fa-check"></i> Free Update</li>
                                                    <li><i class="fas fa-check"></i> High Resolution Printing</li>
                                                    <li><i class="fas fa-check"></i> Branding</li>
                                                    <li><i class="fas fa-check"></i> Another great features</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        */}
                        <ContactForm />
                        <section className="credits">
                            Developed by <span style={{ color: "#ECB365" }}>Patryk Kuna</span><span style={{ display: "block", marginTop: "4px" }}>All rights reserved</span>
                        </section>
                    </div>

                </section>
            </AnimatePage>
        </div>
    );
}


export default Home;
